import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/home",
      name: "home",
      title: '首页',
      component: ()=> import('../views/Home.vue'),
    },
    {
      path: "/login",
      name: "login",
      title: '首页',
      component: ()=> import('../views/Login.vue'),
    },
    // {
    //   path: "/register",
    //   name: "register",
    //   title: '注册',
    //   redirect: '',
    //   component: ()=> import('../views/register.vue'),
    // },
    // {
    //   path: "/findPwdBack",
    //   name: "findPwdBack",
    //   title: '找回密码',
    //   redirect: '',
    //   component: ()=> import('../views/findPwdBack.vue'),
    // },
]

const router = new VueRouter({
	mode: 'hash',
	routes
})

export default router
