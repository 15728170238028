import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'

import { _http } from './js/utils/http';
import _comm from './js/utils/common';
import storage from '@/js/utils/storage';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/base.scss';

Vue.use(ElementUI)
Vue.prototype._http = _http; //api调用方式
Vue.prototype._comm = _comm; //公共方法使用
Vue.prototype._storage = storage;

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#happ')
