<template>
  <div id="happ">
    <router-view></router-view>
    <video id="demosysVedio" autoplay loop muted>
        <source src="./assets/images/ddvedio.mp4" type="video/mp4" />
    </video>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  mounted(){
    let demosysVedioEle = document.getElementById("demosysVedio");
    demosysVedioEle.playbackRate = 0.5;
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFBFC;
}
#demosysVedio{
    position: fixed;  
    right: 0px;  
    bottom: 0px;  
    min-width: 100%;  
    min-height: 100%;  
    height: auto;  
    width: auto;  
    /*加滤镜*/
    /*filter: blur(15px); //背景模糊设置 */
    /*-webkit-filter: grayscale(100%);*/  
    /*filter:grayscale(100%); //背景灰度设置*/  
    z-index:-11;
    
    source{  
        min-width: 100%;  
        min-height: 100%;  
        height: auto;  
        width: auto;  
    }
}
</style>
